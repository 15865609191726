import { ulid } from 'ulid';

import { IFieldMappingConfigKey } from '@site-mate/sitemate-flowsite-shared';

export const generateXeroTimesheetMappings = (
  dashpivotId: string | undefined,
  xeroId: string | undefined
) => {
  const defaultEmployeeFieldUlid = ulid();

  const mappings = [
    {
      _id: ulid(),
      key: IFieldMappingConfigKey.Employee,
      isMultiple: false,
      isRequired: true,
      type: 'single',
      source: {
        isEditable: true,
        fields: [
          {
            _id: defaultEmployeeFieldUlid,
            integrationId: dashpivotId,
            fieldType: 'inputData',
            filter: "{'id': 'form.createdBy', 'label': 'Created By'}",
          },
          {
            _id: ulid(),
            integrationId: dashpivotId,
            fieldType: 'inputData',
            customResolver: 'dashpivotField',
            filter: `form.items[kind='category']`,
          },
          {
            _id: ulid(),
            integrationId: dashpivotId,
            fieldType: 'inputData',
            customResolver: 'dashpivotTableColumnField',
            filter: `form.items[kind in ['table', 'prefilledTable']].columns`,
          },
        ],
        defaultValueData: {
          fieldId: defaultEmployeeFieldUlid,
          fieldType: 'inputData',
          query: 'form.createdBy',
        },
        valueData: undefined,
      },
      destination: {
        isEditable: false,
        fields: [],
        valueData: {
          integrationId: xeroId,
          label: 'Employee',
        },
      },
      metadata: {},
    },
    {
      _id: ulid(),
      key: IFieldMappingConfigKey.Date,
      isMultiple: false,
      isRequired: true,
      type: 'single',
      source: {
        isEditable: true,
        fields: [
          {
            _id: ulid(),
            integrationId: dashpivotId,
            fieldType: 'inputData',
            customResolver: 'dashpivotField',
            filter: `form.items[kind in ['date', 'datePlain']]`,
          },
          {
            _id: ulid(),
            integrationId: dashpivotId,
            fieldType: 'inputData',
            customResolver: 'dashpivotTableColumnField',
            filter: `form.items[kind in ['table', 'prefilledTable']].columns`,
          },
        ],
        valueData: undefined,
      },
      destination: {
        isEditable: false,
        fields: [],
        valueData: {
          integrationId: xeroId,
          label: 'Hours Allocation Date',
        },
      },
      metadata: {},
    },
    {
      _id: ulid(),
      key: IFieldMappingConfigKey.TrackingItem,
      isMultiple: false,
      isRequired: false,
      type: 'single',
      source: {
        isEditable: true,
        fields: [
          {
            _id: ulid(),
            integrationId: dashpivotId,
            fieldType: 'inputData',
            customResolver: 'dashpivotTableColumnField',
            filter: `form.items[kind in ['table', 'prefilledTable']].columns`,
          },
        ],
        valueData: undefined,
      },
      destination: {
        isEditable: false,
        fields: [],
        valueData: {
          integrationId: xeroId,
          label: 'Tracking Item',
        },
      },
      metadata: {},
    },
    {
      _id: ulid(),
      key: IFieldMappingConfigKey.Hours,
      isMultiple: true,
      isRequired: true,
      type: 'multipleSingle',
      section: 'Hours',
      items: [
        {
          _id: ulid(),
          source: {
            isEditable: true,
            fields: [
              {
                _id: ulid(),
                integrationId: dashpivotId,
                fieldType: 'inputData',
                customResolver: 'dashpivotTableColumnField',
                filter:
                  "form.items[kind in ['table', 'prefilledTable']].columns",
              },
            ],
            valueData: undefined,
          },
          destination: {
            isEditable: true,
            fields: [
              {
                _id: ulid(),
                integrationId: xeroId,
                fieldType: 'dropdown',
                optionType: 'dynamic',
                optionSource: '',
                optionParams: [],
              },
            ],
            valueData: undefined,
          },
        },
      ],
      metadata: {},
    },
  ];

  return mappings;
};
